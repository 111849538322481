body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
  overflow-x: hidden;
  background-color: #fff !important;
  font-family: Helvetica; }
  body * {
    font-family: Helvetica; }

.row {
  display: flex;
  justify-content: center;
  align-items: center; }

#root {
  min-height: 100%;
  overflow-x: hidden; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.ant-select-dropdown {
  font-family: Arial, Helvetica, sans-serif !important; }

.ant-select-dropdown-menu-item {
  font-family: Arial, Helvetica, sans-serif !important; }

.policies {
  font-family: Arial;
  max-width: 1000px;
  margin: 24px auto; }
  .policies .h5 {
    font-size: 42px;
    margin-bottom: 24px; }
  .policies .h6 {
    font-size: 32px; }
  .policies .body1 {
    margin: 12px 0; }

#vianoce {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; }

#tree {
  position: relative; }

.rc-select-new > div:first-of-type {
  height: 38px; }
  .rc-select-new > div:first-of-type > div {
    height: 38px; }

.freeze {
  overflow: hidden; }

.generic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh; }
  .generic .ig-link {
    position: absolute;
    bottom: 2vw;
    width: 35px;
    height: 35px; }
    .generic .ig-link > * {
      width: 100%;
      height: 100%;
      fill: #000; }
    .generic .ig-link:hover > * {
      fill: #5c00aa;
      transform: scale(1.1);
      transition: all 0.2s ease-in-out; }
  .generic #pathTraplife:hover,
  .generic #pathTraplife:focus {
    stroke: #5c00aa !important;
    transition: all 0.3s ease-in-out; }
