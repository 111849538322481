@import-normalize;
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800;900&display=swap");
@font-face {
  font-family: "Minecraft";
  src: local("Minecraft"), url("./assets/mc.otf");
}

a {
  text-decoration: none;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

* {
  box-sizing: border-box;
}

html {
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  background-color: #9fdcf8 !important;
}

#root {
  display: flex;
  justify-content: center;
}

#Trapcraft {
  width: 720px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .traplife-logo {
    margin-top: 6vh;
    @media (max-width: "1024px") {
      & {
        margin-top: 3vh;
      }
    }
  }

  .back {
    position: fixed;
    padding: 1rem;
    top: 0;
    left: 0;

    svg {
      stroke: #fff;
      stroke-width: 5%;
    }

    &:hover {
      transform: scale(1.15);
      transition: all 0.3s ease-in-out;
    }
  }

  .landing-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    & > div {
      width: 30vh;
      height: 36vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      @media (max-width: "1024px") {
        & {
          justify-content: unset;
        }
      }

      & > div {
        width: calc(100% + 4px);
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          width: 70%;
          height: auto;
        }

        &:hover {
          transform: scale(1.1);
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  .country-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 3vh;
    margin-bottom: 3vh;

    & > div {
      width: 30vh;
      height: 30vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      & > div {
        width: calc(100% + 4px);
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;

        & > svg {
          width: 100%;
          height: auto;
        }

        &:hover {
          transform: scale(0.95);
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  .text-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 3vh 0;

    .kontajner {
      padding: 3vh 5vw;
    }

    * {
      font-family: "Nunito";
    }

    & > * {
      padding: 0 5vw;
    }

    & > div {
      display: flex;
      flex-direction: column;
    }

    .warn {
      & > *:first-of-type {
        margin-bottom: 1rem;
      }
    }

    b {
      font-weight: 900;
    }

    .status-wrapper {
      width: 100% !important;
    }
  }

  .kontajner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    * {
      font-family: "Minecraft";
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
    }

    .status-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 65%;
    }

    .mcid-input {
      box-sizing: border-box;
      background-color: black;
      border: 2px solid white;
      color: white;
      height: 42px;
      width: calc(100% + 2px);
      padding: 0 8px;
      outline: none;
      margin-bottom: 1.5vw;
      font-size: 16px;
    }

    .check-status {
      margin-top: 1rem;
      font-size: 18px;
    }
  }

  .server {
    &,
    * {
      font-family: "Minecraft";
    }
    box-sizing: border-box;
    text-align: center;
    font-size: 3.5vh;
    color: #662ba5;

    margin-top: 3vh;
    padding-bottom: 6vh;

    label:hover,
    label:focus {
      cursor: url("./assets/copy.svg"), auto;
      color: #fff;
    }

    .copied {
      position: absolute;
      top: 2rem;
      right: 2rem;
      width: 12rem;
      padding: 1rem 2rem;
      font-size: 1.2rem;
      font-family: "Nunito";
      color: #fff;
      background-color: rgba($color: #662ba5, $alpha: 0.9);
      border-radius: 0.5rem;
    }
  }

  .check-button {
    box-sizing: border-box;
    cursor: pointer;
    height: 5vh;
    width: 100%;
    outline: 2px solid black;
    position: relative;
    margin: 0;
    display: inline-block;
    background-color: #6e6e6e;
    padding: 0;

    text-align: center;
    color: white;
    text-shadow: 3px 3px #4c4c4c;
    border-bottom: 4px solid #565656;
    border-right: 2px solid #565656;
    border-left: 2px solid #aaa;
    border-top: 2px solid #aaa;
    font-size: 1.7vh;
  }

  .check-button span {
    font-family: "Minecraft";
  }

  .check-button:hover {
    border-bottom: 4px solid #59639a;
    border-right: 2px solid #59639a;
    border-left: 2px solid #bdc6ff;
    border-top: 2px solid #bdc6ff;
    color: #ffffa0;
    background-color: #7a84bd;
  }
}

@media (max-width: "1079px") {
  .copied {
    top: calc(50vh - 3rem);
    right: 0;
    left: calc(50vw - 6rem);
  }

  .landing-container,
  .country-container {
    flex-direction: column;
  }
}

@media (min-width: "2000px") {
  #Trapcraft {
    width: 1000px;
  }
}
